import React from 'react'

import { Box, PageBreak, Paragraph } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="PageBreak"
    components={[{ component: PageBreak }]}
  >
    <Section title="Sivun katkaisu halutun sisällön jälkeen">
      <Playground>
        <>
          <PageBreak after="page">
            <Box
              color="light"
              height={500}
            >
              <Paragraph>Sivu katkeaa tämän osion jälkeen</Paragraph>
            </Box>
          </PageBreak>
          <Box
            height={500}
            noMargin
          >
            <Paragraph>Sivu katkeaa ennen tätä osiota</Paragraph>
          </Box>
        </>
      </Playground>
    </Section>
    <Section title="Sivun katkaisu ennen haluttua sisältöä">
      <Playground>
        <>
          <Box height={500}>
            <Paragraph>Sivu katkeaa tämän osion jälkeen</Paragraph>
          </Box>
          <PageBreak before="page">
            <Box
              color="light"
              height={500}
              noMargin
            >
              <Paragraph>Sivu katkeaa ennen tätä osiota</Paragraph>
            </Box>
          </PageBreak>
        </>
      </Playground>
    </Section>
    <Section title="Sivun katkaisun välttäminen halutun sisällön kohdalla">
      <Playground>
        <>
          <Box height={500}>
            <Paragraph>Sivu voi katketa tämän osion kohdalla</Paragraph>
          </Box>
          <PageBreak inside="avoid">
            <Box
              color="light"
              height={500}
            >
              <Paragraph>Sivu ei katkea tämän osion kohdalla</Paragraph>
            </Box>
          </PageBreak>
          <Box
            height={500}
            noMargin
          >
            <Paragraph>Sivu voi katketa tämän osion kohdalla</Paragraph>
          </Box>
        </>
      </Playground>
    </Section>
  </Content>
)

export default Page
